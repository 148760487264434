import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import CustomTooltip from './custom-tooltip';

export default function VaccinationCardAction(props) {
  const { id, info, expanded, handleExpandClick, showRemove = false } = props;

  const [
    removeVaccination
  ] = vaccinationsStore(
    (store) => [
      store.removeVaccination,
    ],
    shallow,
  );

  const handleRemoveClick = () => {
    removeVaccination(id);
  };

  return (
    <Box>
      {
        info &&
        <CustomTooltip title={info}>
          <InfoIcon/>
        </CustomTooltip>
      }
      <IconButton onClick={handleExpandClick}>
        {
          expanded
            ? <ExpandLessIcon/>
            : <ExpandMoreIcon/>
        }
      </IconButton>
      {
        !!showRemove &&
        <Tooltip title="Remove">
          <IconButton onClick={handleRemoveClick}>
            <CloseIcon/>
          </IconButton>
        </Tooltip>
      }
    </Box>
  );
}
