import axios from 'axios';
import oktaAuth from '../okta-auth';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  config.customError = new Error('Thrown at:');
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    error.stack = error.config?.customError?.stack;
    return Promise.reject(error);
  });

export default function buildRequest(mainRoute) {
  return async function ({ route = '', type = 'get', data, headers, responseType }) {
    const accessTokenStorage = await oktaAuth.tokenManager.get('accessToken');
    if (!accessTokenStorage) {
      console.error('Cannot get accessToken');
      return null;
    }
    const { accessToken: userToken } = accessTokenStorage;
    const args = [`api/${mainRoute}/${route}`];
    if (data) args.push(data);
    const config = {
      headers: {
        'Authorization': `Bearer ${userToken}`,
        ...headers,
      }
    };
    if (responseType) {
      config.responseType = responseType;
    }
    args.push(config);
    const res = await instance[type](...args);
    return res.data;
  };
}
