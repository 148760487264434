import { useEffect, useMemo, useState } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import CustomDatePicker from './custom-date-picker';
import VaccinationCardAttachment from './vaccination-card-attachment';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import consts from '../consts';

const { MAX_DOSES_PER_VACCINATION_COUNT } = consts;

// TODO

const vaccineTypes = [
  { value: 'Pfizer', id: 1 },
  { value: 'Moderna', id: 2 },
  { value: 'Johnson & Johnson', id: 3 },
  { value: 'AstraZeneca', id: 4 },
  { value: 'Other', id: 5 },
];

const initialInputs = [
  { label: '1st Dose', propName: 'type' },
  {
    label: '2nd Dose',
    propName: 'type2',
    condition: (vaccineType1) => vaccineType1 && vaccineType1 !== 'Johnson & Johnson'
  },
  {
    label: 'Booster Dose #1',
    propName: 'type3',
  },
  {
    label: 'Booster Dose #2',
    propName: 'type4',
  },
];

export default function VaccinationCardContentCovid(props) {
  const { id, showAddDoseButton } = props;

  const [
    handleInput,
    vaccinationsData,
  ] = vaccinationsStore(
    (store) => [
      store.handleInput,
      store.vaccinationsData,
    ],
    shallow,
  );

  const [submittedValue, setSubmittedValue] = useState(null);
  const [additionalInputs, setAdditionalInputs] = useState([]);

  const currentVaccination = useMemo(
    () => vaccinationsData[id],
    [vaccinationsData, id]
  );

  const inputs = useMemo(
    () => [...initialInputs, ...additionalInputs],
    [additionalInputs]
  );

  const vaccineType1 = currentVaccination?.type;
  const hasRid = !!currentVaccination?.rid;

  useEffect(() => {
    if (!submittedValue && hasRid) {
      setSubmittedValue(currentVaccination);
    }
  }, [id, currentVaccination, hasRid, submittedValue]);

  useEffect(() => {
    if (!currentVaccination) return;
    const newAdditionalInputs = [];
    const lastInputPropNameNum = +inputs[inputs.length - 1].propName.replace('type', '');
    for (let propNameNum = lastInputPropNameNum + 1; propNameNum <= MAX_DOSES_PER_VACCINATION_COUNT; propNameNum++) {
      const dose = currentVaccination[`date${propNameNum}`];
      if (!dose) break;
      const label = `Booster Dose #${newAdditionalInputs.length + additionalInputs.length + 3}`;
      newAdditionalInputs.push({
        label,
        propName: `type${propNameNum}`,
      });
    }
    if (!newAdditionalInputs.length) return;
    setAdditionalInputs([...additionalInputs, ...newAdditionalInputs]);
  }, [additionalInputs, currentVaccination, inputs]);

  const handleVaccineTypeChange = (propName) => ({ target: { value } }) => {
    handleInput({ value, id, propName });
  };

  const handleDateChange = (index, propName) => (value) => {
    handleInput({ value, id, propName });
  };

  const handleAddDose = () => {
    setAdditionalInputs([
      ...additionalInputs,
      {
        label: `Booster Dose #${additionalInputs.length + 3}`,
        propName: `type${inputs.length + 1}`,
      }
    ]);
  };

  return (
    <Stack direction="column" justifyContent="space-between" spacing={4}>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={4}>
        <Stack direction="column" justifyContent="flex-start" spacing={1}>
          <Typography variant="overline" gutterBottom>
            Date(s):
          </Typography>
          {
            inputs.reduce((filtered, { label, propName }, index) => {
              if ((vaccineType1 === 'Johnson & Johnson' || !vaccineType1) && index === 1) return filtered;
              const datePropName = `date${index + 1}`;
              filtered.push(
                <CustomDatePicker key={propName}
                                  label={label}
                                  value={currentVaccination?.[datePropName]}
                                  handleInput={handleDateChange(index, datePropName)}
                                  disableFuture
                />
              );
              return filtered;
            }, [])
          }
        </Stack>
        <Stack direction="column" justifyContent="flex-start" spacing={1}>
          <Typography variant="overline" gutterBottom>
            Vaccine Manufacturer(s):
          </Typography>
          {
            inputs.reduce((filtered, { propName, label, condition }, index) => {
              if (condition && !condition(vaccineType1)) return filtered;
              filtered.push(
                <TextField select
                           variant="standard"
                           key={propName}
                           value={currentVaccination?.[propName] || ''}
                           onChange={handleVaccineTypeChange(propName)}
                           size="small"
                           label={label}
                >
                  {
                    vaccineTypes.map(({ value, id }) => (
                      <MenuItem key={id} value={value}>{value}</MenuItem>
                    ))
                  }
                </TextField>
              );
              return filtered;
            }, [])
          }
          {
            !!showAddDoseButton && inputs.length < MAX_DOSES_PER_VACCINATION_COUNT &&
            <Button variant="outlined"
                    onClick={handleAddDose}
                    style={{ marginTop: '10px' }}
                    disabled={!currentVaccination?.[`date${inputs.length}`]}
            >
              <div style={{ lineHeight: 0 }}>
                Add dose
              </div>
              <AddIcon fontSize="small" sx={{ marginLeft: '10px' }}/>
            </Button>
          }
        </Stack>
      </Stack>
      <VaccinationCardAttachment id={id}/>
    </Stack>
  );
}
