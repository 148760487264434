import { getPortalLanguageSection } from '../utils';
import ExemptionsAttachments from './exemptions-attachments';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function ReligiousExemption(props) {
  const { id } = props;

  return (
    <Box>
      <div dangerouslySetInnerHTML={{ __html: getPortalLanguageSection('religious_exemption') }}/>
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <ExemptionsAttachments id={id} text="form for religious vaccine exemption request"/>
      </Stack>
    </Box>
  );
}
