import Container from '@mui/material/Container';
import { getPortalLanguageSection } from '../utils';

export default function Footer() {
  return (
    <footer>
      <Container maxWidth="md">
        <div dangerouslySetInnerHTML={{ __html: getPortalLanguageSection('footer') }}/>
      </Container>
    </footer>
  );
}
