import { useEffect, useState } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import VaccinationCardAction from './vaccination-card-action';
import VaccinationCardContent from './vaccination-card-content';
import VaccinationCardContentCovid from './vaccination-card-content-covid';
import VaccinationCardPreviouslyDiagnosedSection from './vaccination-card-previously-diagnosed-section';
import VaccinationCardTitersSection from './vaccination-card-titers-section';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Exemptions from './exemptions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';

const { VACCINATION_TYPE_RID_MENINGITIS, VACCINATION_TYPE_RID_COVID } = consts;

export default function VaccinationCard(props) {
  const {
    name,
    description,
    info,
    id,
    showPreviouslyDiagnosedSection,
    titerTypes,
    showExemptionCheckbox = true,
    showRemove,
  } = props;

  const [
    vaccinationsData,
    handleInput,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.handleInput,
    ],
    shallow,
  );

  const [expanded, setExpanded] = useState(true);
  const [submittedValue, setSubmittedValue] = useState(null);

  useEffect(() => {
    if (!submittedValue && vaccinationsData[id]?.exemptionRid) {
      setSubmittedValue(vaccinationsData[id]);
    }
  }, [id, vaccinationsData, submittedValue]);

  const isCovid = id === VACCINATION_TYPE_RID_COVID;
  const isExemptionChecked = !!vaccinationsData[id]?.isExemption;
  const isExemptionDisabled = (
    !!submittedValue?.rid
    || !!submittedValue?.exemptionRid
  ) && !!submittedValue?.reason;

  const handleExemptionCheckboxClick = () => {
    const newIsExemptionCheckedValue = !isExemptionChecked;
    handleInput({ value: newIsExemptionCheckedValue, id, propName: 'isExemption' });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ mb: 2, px: 1 }} variant="outlined">
      <CardHeader title={name || ''}
                  subheader={
                    <Stack direction="column" alignItems="flex-start" spacing={1}>
                      {description}
                      {
                        (!!showExemptionCheckbox && id !== VACCINATION_TYPE_RID_MENINGITIS) &&
                        <FormControlLabel disabled={isExemptionDisabled}
                                          control={<Checkbox size="small"/>}
                                          label={
                                            <Typography variant="body2">
                                              I have a vaccination exemption
                                            </Typography>
                                          }
                                          checked={isExemptionChecked}
                                          onChange={handleExemptionCheckboxClick}
                        />
                      }
                    </Stack>
                  }
                  action={
                    <VaccinationCardAction id={id}
                                           info={info}
                                           handleExpandClick={handleExpandClick}
                                           expanded={expanded}
                                           showRemove={showRemove}
                    />
                  }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack direction="column" spacing={3}>
            {
              !isExemptionChecked &&
              (
                isCovid
                  ? <VaccinationCardContentCovid id={id} {...props}/>
                  : <VaccinationCardContent id={id} {...props}/>
              )
            }
            {
              isExemptionChecked &&
              <Exemptions id={id} disabled={isExemptionDisabled}/>
            }
            {
              !!titerTypes &&
              <VaccinationCardTitersSection id={id} {...props}/>
            }
            {
              !!showPreviouslyDiagnosedSection &&
              <VaccinationCardPreviouslyDiagnosedSection id={id} name={name}/>
            }
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
