import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from '../store';
import shallow from 'zustand/shallow';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

export default function Profile() {
  const history = useHistory();

  const { personData, fetchPersonData } = useStore(
    ({ personData, fetchPersonData }) => ({ personData, fetchPersonData }),
    shallow
  );
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    fetchPersonData().catch(() => {
      history.replace('/');
    });
  }, [history, fetchPersonData]);

  const {
    bannerId,
    fullName,
    email,
    role,
    birthDate,
    campusLocation,
    isRemote,
  } = personData || {};

  const getFormattedBirthDate = () => {
    if (!birthDate) return '-';
    const date = new Date(birthDate);
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60000).toLocaleDateString();
  };

  return (
    <Container maxWidth="md">
      {
        !!personData &&
        <Card variant="outlined" sx={{ m: 2 }}>
          <CardHeader
            title={fullName}
            subheader={role}
            action={
              <Button variant="text" onClick={() => oktaAuth.signOut()}>
                Sign Out
              </Button>
            }
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                Banner ID:
              </Grid>
              <Grid item xs={10}>
                {bannerId}
              </Grid>

              <Grid item xs={2}>
                Email:
              </Grid>
              <Grid item xs={10}>
                {email || '-'}
              </Grid>

              <Grid item xs={2}>
                Date of Birth:
              </Grid>
              <Grid item xs={10}>
                {getFormattedBirthDate()}
              </Grid>

              <Grid item xs={2}>
                Campus Location:
              </Grid>
              <Grid item xs={10}>
                {campusLocation || '-'}
              </Grid>

              <Grid item xs={2}>
                Remote:
              </Grid>
              <Grid item xs={10}>
                {isRemote ? 'Yes' : 'No'}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
    </Container>
  );
}
