import { useEffect, useState } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import SingleAttachment from './single-attachment';
import CustomDatePicker from './custom-date-picker';

export default function VaccinationCardPreviouslyDiagnosedSection(props) {
  const { name, id } = props;

  const [
    previouslyDiagnosedData,
    onPreviouslyDiagnosedDataChange,
  ] = vaccinationsStore(
    (store) => [
      store.previouslyDiagnosedData,
      store.onPreviouslyDiagnosedDataChange,
    ],
    shallow,
  );

  const {
    attachment,
    rid,
    year,
    attachmentObjectUrl,
  } = previouslyDiagnosedData[id] || {};

  const [isPreviouslyDiagnosedChecked, setIsPreviouslyDiagnosedChecked] = useState(false);
  const [yearDate, setYearDate] = useState(year ? new Date(year.toString()) : null);

  useEffect(() => {
    if (rid) {
      setIsPreviouslyDiagnosedChecked(true);
    }
  }, [rid, setIsPreviouslyDiagnosedChecked]);

  const handleCheckboxChange = () => {
    const newValue = !isPreviouslyDiagnosedChecked;
    setIsPreviouslyDiagnosedChecked(newValue);
    onPreviouslyDiagnosedDataChange({
      id,
      valuesToMerge: {
        needToSave: newValue,
      },
    });
  };

  const handleAttachmentInputChange = (valuesToMerge) => {
    onPreviouslyDiagnosedDataChange({
      id,
      valuesToMerge,
    });
  };

  const handlePreviouslyDiagnosedYearInput = (value) => {
    onPreviouslyDiagnosedDataChange({
      id,
      valuesToMerge: {
        year: value?._d?.getFullYear(),
      },
    });
    setYearDate(value);
  };

  return (
    <>
      <Divider/>
      <FormControl>
        <FormControlLabel control={<Checkbox size="small"/>}
                          label={
                            <Typography variant="body2">
                              I have been previously diagnosed with "{name}"
                            </Typography>
                          }
                          checked={isPreviouslyDiagnosedChecked}
                          onChange={handleCheckboxChange}
                          disabled={!!rid}
        />
      </FormControl>
      {
        isPreviouslyDiagnosedChecked &&
        <Stack direction="row" justifyContent="flex-start" spacing={4}>
          <Stack direction="column" spacing={1}>
            <Typography variant="overline">
              Year of diagnosis:
            </Typography>
            <CustomDatePicker value={yearDate}
                              disableFuture
                              handleInput={handlePreviouslyDiagnosedYearInput}
                              additionalProps={{
                                closeOnSelect: true,
                                openTo: 'year',
                                views: ['year'],
                              }}
            />
          </Stack>
          <Stack direction="column" justifyContent="space-between" spacing={1}>
            <Typography variant="overline">
              Attachment for doctor’s note:
            </Typography>
            <SingleAttachment handleChange={handleAttachmentInputChange}
                              attachment={attachment}
                              attachmentKey="attachment"
                              type="pd"
                              rid={rid}
                              objectUrl={attachmentObjectUrl}
            />
          </Stack>
        </Stack>
      }
    </>
  );
}