import { useStore } from '../store';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import VaccinationCard from './vaccination-card';
import VaccinationsAttachments from './vaccinations-attachments';
import OtherVaccinations from '../components/other-vaccinations';

const { VACCINATION_TYPE_RID_COVID } = consts;

export default function RecommendedVaccinations() {
  const [
    personData = {},
  ] = useStore(
    (store) => [
      store.personData,
    ],
    shallow,
  );

  const [
    vaccinationTypes,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationTypes,
    ],
    shallow
  );

  return (
    <>
      {
        vaccinationTypes.reduce((filtered, vaccinationType) => {
          if (vaccinationType.isOther) return filtered;
          const isRequired = (
            (
              personData.role === 'Student'
              || (personData.role === 'Employee' && personData.isActive && !personData.isRemote)
            ) && vaccinationType.requiredForStudents
          ) || (personData.role === 'Employee' && vaccinationType.requiredForEmployees);

          if (!isRequired) {
            if (vaccinationType.id === VACCINATION_TYPE_RID_COVID) {
              filtered = [
                <VaccinationCard key={vaccinationType.id}
                                 {...vaccinationType}
                                 showExemptionCheckbox={false}
                />,
                ...filtered,
              ];
            } else {
              filtered = [
                ...filtered,
                <VaccinationCard key={vaccinationType.id}
                                 {...vaccinationType}
                                 showExemptionCheckbox={false}
                />
              ];
            }
          }
          return filtered;
        }, [])
      }
      <OtherVaccinations/>
      <VaccinationsAttachments/>
    </>
  );
}
