import { useStore } from '../store';
import shallow from 'zustand/shallow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function AttachmentDialog() {
  const [
    {
      url: objUrl,
      isImage,
      fileName,
    },
    closeAttachmentDialog,
    isAttachmentDialogOpened,
  ] = useStore(
    (store) => [
      store.attachmentDialogObject,
      store.closeAttachmentDialog,
      store.isAttachmentDialogOpened,
    ],
    shallow
  );

  return (
    <Dialog onClose={closeAttachmentDialog} open={isAttachmentDialogOpened} fullWidth maxWidth="md">
      {
        objUrl &&
        <Box src={objUrl}
             component={isImage ? 'img' : 'iframe'}
             sx={{ border: 'none', height: isImage ? 'unset' : '100vh' }}
        />
      }
      <DialogActions sx={{ position: 'absolute', float: 'right', width: '-webkit-fill-available' }}>
        <Button href={objUrl}
                download={fileName}
                variant="contained"
                size="small"
                endIcon={<DownloadIcon fontSize="small"/>}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};