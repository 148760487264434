import { useStore as useVaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import { getPortalLanguageSection } from '../utils';
import possibleContraindications from '../consts/contraindications.json';
import ExemptionsAttachments from './exemptions-attachments';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function MedicalExemption(props) {
  const { id } = props;

  const [
    vaccinationsData,
    handleInput,
    areExemptionFieldsValuesMissing,
  ] = useVaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.handleInput,
      store.areExemptionFieldsValuesMissing,
    ],
    shallow
  );

  const handleContraindicationsChange = ({ target: { value } }) => {
    handleInput({ value, id, propName: 'contraindications' });
  };

  return (
    <Box>
      <div dangerouslySetInnerHTML={{ __html: getPortalLanguageSection('medical_exemption') }}/>
      <Stack direction="column" alignItems="flex-start" spacing={3}>
        <TextField sx={{ width: '300px' }}
                   select
                   required
                   variant="standard"
                   error={!vaccinationsData[id]?.contraindications && areExemptionFieldsValuesMissing}
                   value={vaccinationsData[id]?.contraindications || ''}
                   onChange={handleContraindicationsChange}
                   size="small"
                   label="Contraindications"
                   helperText="This is to certify that the person has the following medical contraindication to the vaccination"
        >
          {
            possibleContraindications.map((value, index) => (
              <MenuItem key={index} value={value}>{value}</MenuItem>
            ))
          }
        </TextField>
        <ExemptionsAttachments id={id} text="letter from your primary licensed healthcare provider"/>
      </Stack>
    </Box>
  );
}
