import { useStore as notificationStore } from './notification-store';
import shallow from 'zustand/shallow';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function Notification() {
  const [
    hideNotification,
    isNotificationShown,
    notificationMessage,
    notificationSeverity,
  ] = notificationStore(
    (store) => [
      store.hideNotification,
      store.isNotificationShown,
      store.notificationMessage,
      store.notificationSeverity,
    ],
    shallow
  );
  
  return (
    <Snackbar open={isNotificationShown}
              autoHideDuration={6000}
              onClose={hideNotification}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={hideNotification} severity={notificationSeverity} variant="filled">
        {notificationMessage}
      </Alert>
    </Snackbar>
  );
}
