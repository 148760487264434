import create from 'zustand';
import * as api from '../api/vaccinations';
import { get as getDiseasesHistory, save as saveDiseasesHistory } from '../api/diseases-history';
import { get as getTiters, getTiterTypes, save as saveTiters } from '../api/titers';
import consts from '../consts';

const { MAX_CARD_ATTACHMENTS_COUNT, MAX_EXEMPTION_ATTACHMENTS_COUNT } = consts;

function getFormattedFiles({ files, attachments }) {
  const res = [];
  for (let file of files) {
    let name = file.name;
    let count = 1;
    // eslint-disable-next-line no-loop-func
    while (attachments.find((curr) => curr.name === name)) {
      const array = file.name.split('.');
      array[array.length - 2] = `${array[array.length - 2]}(${count++})`;
      name = array.join('.');
    }
    file = new File([file], name, { type: file.type });
    res.push(file);
  }
  return res;
}

const useStore = create((set, get) => ({
  vaccinationsData: {},
  vaccinationTypes: [],

  vaccinationsAttachments: [],
  newVaccinationsAttachments: [],

  previouslyDiagnosedData: {},

  titers: [],
  titerTypes: {},

  openedTiterDialogObject: null,
  setOpenedTiterDialogObject: (v) => set({ openedTiterDialogObject: v }),

  areExemptionFieldsValuesMissing: false,
  setAreExemptionFieldsValuesMissing: (v) => set({ areExemptionFieldsValuesMissing: v }),

  throwMissingFieldsError: (userMessage) => {
    window.scroll(0, 0);
    const error = new Error();
    error.userMessage = userMessage || 'Please, fill all the required fields';
    throw error;
  },

  onPreviouslyDiagnosedDataChange: ({ valuesToMerge, id }) => set(({ previouslyDiagnosedData }) => {
    return {
      previouslyDiagnosedData: {
        ...previouslyDiagnosedData,
        [id]: {
          ...(previouslyDiagnosedData[id] || {}),
          ...valuesToMerge,
        }
      }
    };
  }),

  onTitersChange: ({ valuesToMerge, index }) => set(({ titers }) => {
    const currIndex = typeof index === 'undefined' ? titers.length : index;
    titers[currIndex] = {
      ...valuesToMerge,
      commonIndex: currIndex,
    };
    return {
      titers: [
        ...titers,
      ]
    };
  }),

  fetchVaccinationData: async () => {
    const vaccinationsData = await api.getVaccinationsData();
    const exemptionsData = await api.getExemptionsData();
    const vaccinationsAttachments = await api.getVaccinationsAttachments();
    const vaccinationTypes = await api.getVaccinationsTypes();
    const previouslyDiagnosedData = await getDiseasesHistory();
    const titerTypes = await getTiterTypes();
    const titersPlain = await getTiters();
    const titers = titersPlain.map((titer, index) => ({
      ...titer,
      vaccinationType: titerTypes[titer.type]?.vaccinationType,
      commonIndex: index,
    }));

    for (const key in exemptionsData) {
      const { reason, contraindications, exemptionsAttachments, rid } = exemptionsData[key];
      vaccinationsData[key] = {
        ...vaccinationsData[key],
        isExemption: true,
        reason,
        contraindications,
        exemptionsAttachments: exemptionsAttachments.map((v) => ({ ...v, rid })),
        exemptionRid: rid,
      };
    }

    const titerTypesEntries = Object.entries(titerTypes);
    const titerTypesInVaccinationsTypes = {};

    titerTypesEntries
      .forEach(([titerType, { vaccinationType, name }]) => {
        titerTypesInVaccinationsTypes[vaccinationType] = [
          ...(titerTypesInVaccinationsTypes[vaccinationType] || []),
          {
            id: titerType,
            name,
          },
        ];
      });

    vaccinationTypes.sort((a, b) => a.name > b.name ? 1 : -1);

    const vaccinationTypesMapped = vaccinationTypes.map((vaccinationType) => {
      return {
        ...vaccinationType,
        titerTypes: titerTypesInVaccinationsTypes[vaccinationType.id],
      };
    });

    set({
      vaccinationsData,
      vaccinationTypes: vaccinationTypesMapped,
      vaccinationsAttachments,
      previouslyDiagnosedData,
      titers,
      titerTypes,
    });
  },

  handleSubmitVaccinations: async () => {
    const {
      vaccinationsData,
      fetchVaccinationData,
      throwMissingFieldsError,
      setAreExemptionFieldsValuesMissing,
      handleSubmitTiters,
      handleSubmitVaccinationsAttachments,
      handleSubmitDiseasesHistory,
    } = get();

    if (!vaccinationsData || !Object.keys(vaccinationsData).length) return;
    const missingRequiredFieldsInExemption = Object.entries(vaccinationsData)
      .some(([, value]) => {
        return value?.isExemption && (
          !value.reason
          || !value.exemptionsAttachments?.length
          || (
            value.reason === 'Medical'
            && !value.contraindications
          )
        )
      });
    if (missingRequiredFieldsInExemption) {
      setAreExemptionFieldsValuesMissing(true);
      throwMissingFieldsError('Please, fill all the required fields in your exemption submissions');
    }

    await api.save(vaccinationsData);
    await handleSubmitVaccinationsAttachments();
    await handleSubmitDiseasesHistory();
    await handleSubmitTiters();

    // TODO: disable inputs (or step idk) until fetch is done
    fetchVaccinationData();
  },

  handleSubmitVaccinationsAttachments: async () => {
    const { newVaccinationsAttachments } = get();
    if (!newVaccinationsAttachments.length) return;
    await api.saveVaccinationsAttachments(newVaccinationsAttachments);
    newVaccinationsAttachments.forEach(({ objectUrl }) => {
      URL.revokeObjectURL(objectUrl);
    });
    set({ newVaccinationsAttachments: [] }); // ?
  },

  handleSubmitTiters: async () => {
    const { titers } = get();

    await saveTiters({
      titers: titers.map((titer) => {
        return {
          attachment: titer.attachment,
          date: titer.date,
          result: titer.result,
          value: titer.value,
          type: titer.type,
          rid: titer.rid,
        };
      }),
    });
    titers.forEach(({ attachmentObjectUrl }) => {
      if (attachmentObjectUrl) {
        URL.revokeObjectURL(attachmentObjectUrl);
      }
    });
  },

  handleSubmitDiseasesHistory: async () => {
    const { previouslyDiagnosedData } = get();
    const previouslyDiagnosedDataEntries = Object.entries(previouslyDiagnosedData);
    if (!previouslyDiagnosedDataEntries.length) return;
    const previouslyDiagnosedDataFiltered = {};
    previouslyDiagnosedDataEntries.forEach(([key, value]) => {
      if (value.rid || (!value.rid && value.needToSave)) {
        previouslyDiagnosedDataFiltered[key] = value;
      }
    });
    await saveDiseasesHistory(previouslyDiagnosedDataFiltered);
    Object.entries(previouslyDiagnosedData)
      .forEach(([, { attachment }]) => {
        if (attachment?.objectUrl) {
          URL.revokeObjectURL(attachment.objectUrl);
        }
      });
  },

  handleInput: ({ value, id, propName }) => set(({ vaccinationsData }) => {
    const item = vaccinationsData[id] || {};
    const updated = {
      ...item, [propName]: value
    };
    return {
      vaccinationsData: { ...vaccinationsData, [id]: updated }
    };
  }),

  removeVaccination: (id) => set(({ vaccinationsData }) => {
    const newVaccinationsDataEntries = Object.entries(vaccinationsData).filter(([itemId]) => parseInt(itemId) !== id);
    return { vaccinationsData: Object.fromEntries(newVaccinationsDataEntries) };
  }),

  addVaccination: (id) => set(({ vaccinationsData }) => {
    return {
      vaccinationsData: {
        [id]: { dateCreated: new Date() },
        ...vaccinationsData,
      }
    };
  }),

  handleAttachmentsInput: ({ files, id }) => set(({ newVaccinationsAttachments, vaccinationsAttachments }) => {
    const newAttachments = getFormattedFiles({
      files,
      attachments: [
        ...vaccinationsAttachments,
        ...newVaccinationsAttachments
      ].filter((curr) => curr.id === id).map(({ value }) => value)
    });
    return {
      newVaccinationsAttachments: [
        ...newVaccinationsAttachments,
        ...newAttachments.map((value) => ({ value, id, objectUrl: URL.createObjectURL(value) }))
      ]
    };
  }),

  // separate = not common = not from vaccinationsAttachments I guess. probably needs to be refactored as well as the bunch of the other code
  handleSeparateAttachmentsInput: ({ files, id }) => set(({ vaccinationsData }) => {
    const item = vaccinationsData[id] || {};
    const isExemption = !!item.isExemption;
    const attachmentsKey = isExemption ? 'exemptionsAttachments' : 'attachments';
    const prevAttachments = item[attachmentsKey] || [];
    const newAttachments = getFormattedFiles({ files, attachments: prevAttachments });
    const attachments = [...prevAttachments, ...newAttachments];
    const max = isExemption ? MAX_EXEMPTION_ATTACHMENTS_COUNT : MAX_CARD_ATTACHMENTS_COUNT;
    if (attachments.length >= max) {
      attachments.splice(max, attachments.length);
    }
    const updated = { ...item, [attachmentsKey]: attachments };
    return {
      vaccinationsData: { ...vaccinationsData, [id]: updated }
    };
  }),

  deleteAttachment: ({ index, id, isCommon = false, key = 'attachments' }) => {
    set(({ vaccinationsData, newVaccinationsAttachments }) => {
      if (isCommon) {
        const [deletedAttachment] = newVaccinationsAttachments.splice(index, 1);
        URL.revokeObjectURL(deletedAttachment.objectUrl);
        return {
          newVaccinationsAttachments: [...newVaccinationsAttachments]
        };
      }
      const item = vaccinationsData[id];
      item[key].splice(index, 1);
      const updated = {
        ...item, [key]: [...item[key]],
      };
      return {
        vaccinationsData: { ...vaccinationsData, [id]: updated }
      };
    });
  },
}));

export { useStore };
