import React, { useState } from 'react';
import shallow from 'zustand/shallow';
import { useStore } from '../store';
import { useStore as useTBStore } from './tuberculosis-store';
import { getPortalLanguageSection } from '../utils';
import SingleAttachment from './single-attachment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TuberculosisSkinTestsSection from './tuberculosis-skin-tests-section';
import TuberculosisBloodTestsSection from './tuberculosis-blood-tests-section';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import CustomDatePicker from './custom-date-picker';
import TBquestions from '../consts/tb-tests.json';

export default function TuberculosisHistory() {
  const [personData] = useStore((store) => [store.personData], shallow);

  const [tbHistoryData, onTbTreatmentChange] = useTBStore(
    (store) => [store.tbHistoryData, store.onTbTreatmentChange],
    shallow
  );

  const {
    xrayTestDate,
    xrayResult,
    treatmentName,
    treatmentDateFrom,
    treatmentDateTo,
    clinicName,
    clinicAddress,
    phoneNumber,
    xrayAttachment,
    rid,
    attachmentObjectUrl,
  } = tbHistoryData?.treatment || {};
  const [questionnaire, setQuestionnaire] = useState({});

  const handleRadioChange = (e, key) => {
    const updatedQuestionnaire = { ...questionnaire };
    updatedQuestionnaire[key] = e.target.value === '1'; // Convert "1" to true, "0" to false
    setQuestionnaire(updatedQuestionnaire);
  };
  const handleDateChange = (newValue, field) => {
    if (field === 'from' && treatmentDateTo < newValue) {
      // Swap if "To" is before "From"

      onTbTreatmentChange({
        treatmentDateFrom: treatmentDateTo,
        treatmentDateTo: newValue,
      });
    } else if (field === 'to' && newValue < treatmentDateFrom) {
      // Swap if "From" is after "To"

      onTbTreatmentChange({
        treatmentDateFrom: newValue,
        treatmentDateTo: treatmentDateFrom,
      });
    } else {
      // No swap needed, update the respective field
      if (field === 'from') {
        onTbTreatmentChange({ treatmentDateFrom: newValue });
      } else if (field === 'to') {
        onTbTreatmentChange({ treatmentDateTo: newValue });
      }
    }
  };

  const questionnaireCompleted = Object.keys(questionnaire).length === 4;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1, mx: 1 }}>
      <div
        dangerouslySetInnerHTML={{
          __html: getPortalLanguageSection(
            personData?.noNeedTbTest
              ? 'tb_questionnaire_answered_no'
              : 'tb_questionnaire_answered_yes'
          ),
        }}
      />
      <Typography variant="overline" gutterBottom>
        Please answer the following questions:
      </Typography>
      <Stack direction="column" justifyContent="space-between" spacing={2}>
        {TBquestions.map(({ value, key }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} key={key}>
            <FormControl>
              <RadioGroup
                row
                value={questionnaire[key]}
                onChange={(e) => handleRadioChange(e, key)}
              >
                <FormControlLabel
                  label="Yes"
                  value={1}
                  control={<Radio size="small" />}
                />
                <FormControlLabel
                  label="No"
                  value={0}
                  control={<Radio size="small" />}
                />
              </RadioGroup>
            </FormControl>
            <Typography variant="body2" sx={{ maxWidth: '80%', py: 1 }}>
              {value}
            </Typography>
          </Box>
        ))}

        {questionnaire['skin'] && questionnaireCompleted && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: getPortalLanguageSection('tb_history'),
              }}
            />
            <TuberculosisSkinTestsSection />
          </div>
        )}
        {questionnaire['blood'] && questionnaireCompleted && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: getPortalLanguageSection('tb_history_2'),
              }}
            />
            <TuberculosisBloodTestsSection />
          </div>
        )}

        {questionnaire['xray'] && questionnaireCompleted && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: getPortalLanguageSection('tb_history_3'),
              }}
            />
            <Card variant="outlined" sx={{ paddingX: '10px' }}>
              <CardHeader
                title="Chest X-ray required if TB test is positive"
                subheader="(Attach written medical interpretation of Chest X-ray in English)"
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={4}
                >
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Typography variant="overline" gutterBottom>
                      Date:
                    </Typography>
                    <CustomDatePicker
                      value={xrayTestDate}
                      disableFuture
                      handleInput={(newValue) => {
                        onTbTreatmentChange({ xrayTestDate: newValue });
                      }}
                    />
                    <SingleAttachment
                      handleChange={onTbTreatmentChange}
                      attachment={xrayAttachment}
                      attachmentKey="xrayAttachment"
                      type="tb"
                      rid={rid}
                      objectUrl={attachmentObjectUrl}
                    />
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Typography variant="overline" gutterBottom>
                      Result:
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        value={xrayResult || ''}
                        onChange={(e) =>
                          onTbTreatmentChange({ xrayResult: e.target.value })
                        }
                      >
                        <FormControlLabel
                          label="NORMAL"
                          value="Normal"
                          control={<Radio size="small" />}
                        />
                        <FormControlLabel
                          label="ABNORMAL"
                          value="Abnormal"
                          control={<Radio size="small" />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </div>
        )}
        {questionnaire['treatment'] && questionnaireCompleted && (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: getPortalLanguageSection('tb_history_4'),
                }}
              />
              <Card variant="outlined" sx={{ paddingX: '10px' }}>
                <CardHeader
                  title="I have received treatment for TB"
                  titleTypographyProps={{ variant: 'h6' }}
                />
                <CardContent>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <Stack direction="row" spacing={2}>
                      <Typography variant="overline">
                        Name of Treatment:
                      </Typography>
                      <TextField
                        select
                        sx={{ minWidth: '30%' }}
                        variant="standard"
                        size="small"
                        margin="dense"
                        autoComplete="off"
                        value={treatmentName || ''}
                        onChange={(e) =>
                          onTbTreatmentChange({ treatmentName: e.target.value })
                        }
                      >
                        <MenuItem value="INH">INH</MenuItem>
                        <MenuItem value="Rifampin">Rifampin</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <Typography variant="overline">
                        Dates of treatment:
                      </Typography>
                      <CustomDatePicker
                        value={treatmentDateFrom}
                        label="From"
                        disableFuture
                        handleInput={(newValue) =>
                          handleDateChange(newValue, 'from')
                        }
                      />
                      <CustomDatePicker
                        value={treatmentDateTo}
                        label="To"
                        disableFuture
                        handleInput={(newValue) =>
                          handleDateChange(newValue, 'to')
                        }
                      />
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="overline">
                        Physician/ Clinic name:
                      </Typography>
                      <TextField
                        fullWidth
                        variant="standard"
                        size="small"
                        margin="dense"
                        autoComplete="off"
                        value={clinicName || ''}
                        onChange={(e) =>
                          onTbTreatmentChange({ clinicName: e.target.value })
                        }
                      />
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="overline">
                        Physician/ Clinic address:
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        variant="standard"
                        size="small"
                        margin="dense"
                        autoComplete="off"
                        value={clinicAddress || ''}
                        onChange={(e) =>
                          onTbTreatmentChange({ clinicAddress: e.target.value })
                        }
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="overline" gutterBottom>
                        Phone number:
                      </Typography>
                      <TextField
                        variant="standard"
                        size="small"
                        margin="normal"
                        autoComplete="off"
                        value={phoneNumber || ''}
                        onChange={(e) =>
                          onTbTreatmentChange({ phoneNumber: e.target.value })
                        }
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </div>
          )}
      </Stack>
    </Box>
  );
}
