import { useState } from 'react';
import { useStore as useVaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import AttachmentsChips from './attachments-chips';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const { MAX_EXEMPTION_ATTACHMENTS_COUNT } = consts;

export default function ExemptionsAttachments(props) {
  const { id, text } = props;

  const [attachments, setAttachments] = useState('');

  const [
    vaccinationsData,
    handleSeparateAttachmentsInput,
    areExemptionFieldsValuesMissing,
  ] = useVaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.handleSeparateAttachmentsInput,
      store.areExemptionFieldsValuesMissing,
    ],
    shallow
  );

  const handleFilesUpload = async ({ target: { files } }) => {
    handleSeparateAttachmentsInput({ files, id });
    setAttachments('');
  };

  const isAttachmentButtonDisabled = vaccinationsData[id]?.exemptionsAttachments?.length >= MAX_EXEMPTION_ATTACHMENTS_COUNT;

  return (
    <Stack direction="column" justifyContent="space-between" spacing={2}>
      <label htmlFor={`exemp-attachment-${id}`}>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Typography variant="overline"
                      sx={{ lineHeight: 2 }}
                      color={
                        (!vaccinationsData[id]?.exemptionsAttachments?.length && areExemptionFieldsValuesMissing)
                          ? 'error'
                          : 'standard'
                      }
          >
            Please, upload {text} and any additional supporting
            information (up to {MAX_EXEMPTION_ATTACHMENTS_COUNT} files)*:
          </Typography>
          <Button variant="contained" size="small" component="span" disabled={isAttachmentButtonDisabled}>
            Attach files*
          </Button>
        </Stack>
        <input
          hidden
          multiple
          id={`exemp-attachment-${id}`}
          type="file"
          value={attachments}
          onChange={handleFilesUpload}
        />
      </label>
      <AttachmentsChips attachmentsKey="exemptionsAttachments" id={id}/>
    </Stack>
  );
}
