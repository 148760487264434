import buildRequest from './index';

const request = buildRequest('tb-history');

export async function get() {
  return request({});
}

export async function save(data) {
  return request({
    type: 'put',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function saveQuestionnaire(data) {
  return request({
    route: 'questionnaire',
    type: 'put',
    data,
  });
}
