import { useState, useEffect, useMemo } from 'react';
import { useStore } from '../store';
import { useStore as useVaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import CustomTooltip from './custom-tooltip';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Divider from '@mui/material/Divider';

const resultsMap = {
  POS: 'Positive',
  LOW: 'Low',
  EQUIV: 'Equivocal',
};

export default function VaccinationCardTitersSection(props) {
  const { id: vaccinationType, titersInfo, titersDescription } = props;

  const [
    setOpenedTiterDialogObject,
    titers,
    titerTypes,
  ] = useVaccinationsStore(
    (store) => [
      store.setOpenedTiterDialogObject,
      store.titers,
      store.titerTypes,
    ],
    shallow,
  );

  const [
    handleAttachmentChipClick,
  ] = useStore(
    (store) => [
      store.handleAttachmentChipClick,
    ],
    shallow,
  );

  const [expanded, setExpanded] = useState(false);

  const currentTypeTitersList = useMemo(
    () => titers?.filter((titer) => titer.vaccinationType === vaccinationType),
    [titers, vaccinationType]);

  useEffect(() => {
    setExpanded(!!currentTypeTitersList?.length);
  }, [currentTypeTitersList]);

  const handleAttachmentClick = ({ rid, name, objectUrl }) => {
    if (!rid && !objectUrl) return;
    handleAttachmentChipClick({ rid, objectUrl, fileName: name, type: 'titers' });
  };

  const handleEditClick = ({ vaccinationType, index, isApproved }) => () => {
    if (isApproved) return;
    setOpenedTiterDialogObject({ index, vaccinationType });
  };

  const handleAddClick = () => {
    setOpenedTiterDialogObject({ vaccinationType });
  };

  return (
    <Paper variant="outlined">
      <Toolbar variant="dense" disableGutters sx={{ paddingX: '10px' }}>
        <IconButton onClick={() => setExpanded(!expanded)}
                    sx={{ display: currentTypeTitersList?.length ? '' : 'none' }}
        >
          {expanded ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
        <Typography variant="overline"
                    sx={{ flex: '1 1 100%', paddingX: currentTypeTitersList.length ? '' : '10px' }}
        >
          Titers
        </Typography>
        {
          titersInfo &&
          <CustomTooltip title={titersInfo}>
            <InfoIcon/>
          </CustomTooltip>
        }
        <Tooltip title="Add titer" placement="top-start">
          <IconButton color="primary" onClick={handleAddClick}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer>
        {
          titersDescription &&
          <Typography variant="body1" sx={{ margin: '0 16px 16px', color: '#00000099' }}>
            {titersDescription}
          </Typography>
        }
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider/>
          <Table size="small">
            <TableHead>
              <TableRow>
                {
                  [
                    '',
                    'Type:',
                    'Result:',
                    'Value:',
                    'Date:',
                    'Attachment:'
                  ].map((curr, index) => {
                    return (
                      <TableCell key={index}>
                        <Typography variant="overline">
                          {curr}
                        </Typography>
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                currentTypeTitersList?.map((titer, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {
                        titer.isApproved
                          ?
                          <Typography variant="caption" color="green">
                            APPROVED
                          </Typography>
                          : <IconButton size="small"
                                        onClick={handleEditClick({
                                          vaccinationType,
                                          index: titer.commonIndex,
                                          isApproved: titer.isApproved,
                                        })}
                                        disabled={!!titer.isApproved}
                          >
                            <EditIcon fontSize="inherit"/>
                          </IconButton>
                      }
                    </TableCell>
                    <TableCell>
                      {titerTypes[titer.type].name}
                    </TableCell>
                    <TableCell>
                      {resultsMap[titer.result]}
                    </TableCell>
                    <TableCell>
                      {titer.value}
                    </TableCell>
                    <TableCell>
                      {titer.date ? new Date(titer.date).toLocaleDateString() : ''}
                    </TableCell>
                    <TableCell>
                      {
                        !!titer.attachment &&
                        <Chip clickable
                              size="small"
                              label={titer.attachment.filename || titer.attachment.name}
                              onClick={() => handleAttachmentClick({
                                rid: titer.rid,
                                name: titer.attachment.filename || titer.attachment.name,
                                objectUrl: titer.attachmentObjectUrl,
                              })}
                              sx={{ maxWidth: '150px' }}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Collapse>
      </TableContainer>
    </Paper>
  );
}