import buildRequest from './index';

const request = buildRequest('meningitis-waivers');

export async function getWaiver() {
  return request({});
}

export async function saveWaiver(data) {
  return request({
    type: 'put',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
