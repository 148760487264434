import { useStore as useVaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import MedicalExemption from './medical-exemption';
import ReligiousExemption from './religious-exemption';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const exemptionReasons = [
  {
    value: 'Medical',
    render: (props) => <MedicalExemption {...props}/>
  },
  {
    value: 'Religious',
    render: (props) => <ReligiousExemption {...props}/>
  },
];

export default function Exemptions(props) {
  const { id, disabled } = props;

  const [
    vaccinationsData,
    handleInput,
    areExemptionFieldsValuesMissing,
  ] = useVaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.handleInput,
      store.areExemptionFieldsValuesMissing,
    ],
    shallow
  );


  const exemptionReason = vaccinationsData[id]?.reason;

  const handleExemptionReasonChange = ({ target: { value } }) => {
    handleInput({ value, id, propName: 'reason' });
  };

  return (
    <Stack direction="column" spacing={2} sx={{ minWidth: { md: '300px' }, mb: 5 }}>
      <TextField select
                 required
                 disabled={disabled}
                 variant="standard"
                 error={!exemptionReason && areExemptionFieldsValuesMissing}
                 value={exemptionReason || ''}
                 onChange={handleExemptionReasonChange}
                 size="small"
                 label="Reason for Exemption"
      >
        {
          exemptionReasons.map(({ value }, index) => (
            <MenuItem key={index} value={value}>{value}</MenuItem>
          ))
        }
      </TextField>
      {
        exemptionReasons.find(({ value }) => value === exemptionReason)?.render({ id })
      }
      {
        exemptionReason &&
        <Typography variant="caption">
          By submitting this, I attest that the statements above are true and accurate and
          understand that submitting false information on this official University form would violate University
          policy and subject me to sanctions including potential removal from the University.
        </Typography>
      }
    </Stack>
  );
}
