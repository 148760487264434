import { useMemo, useState, useEffect } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import CustomDatePicker from './custom-date-picker';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import consts from '../consts';

const { MAX_DOSES_PER_VACCINATION_COUNT } = consts;

export default function VaccinationCardContent(props) {
  const { dates: initialDates = [], showIsPrimarySeriesComplete, id, showAddDoseButton } = props;

  const [
    handleInput,
    vaccinationsData,
  ] = vaccinationsStore(
    (store) => [
      store.handleInput,
      store.vaccinationsData,
    ],
    shallow,
  );

  const [additionalDates, setAdditionalDates] = useState([]);

  const currentVaccination = useMemo(
    () => vaccinationsData[id],
    [vaccinationsData, id]
  );

  const dates = useMemo(
    () => [...initialDates, ...additionalDates],
    [initialDates, additionalDates]
  );

  const datesColumns = useMemo(
    () => {
      const columns = [[], []];
      [...dates].forEach(({ label, orderNumber }) => {
        const lowerCased = label.toLowerCase();
        const isBooster = lowerCased.includes('booster') || lowerCased.includes('additional');
        columns[isBooster ? 1 : 0].push({ label, orderNumber });
      });
      return columns;
    },
    [dates]
  );

  useEffect(() => {
    if (!currentVaccination) return;
    const newAdditionalDates = [];
    const lastDateOrderNumber = dates[dates.length - 1].orderNumber;
    for (let orderNumber = lastDateOrderNumber + 1; orderNumber <= MAX_DOSES_PER_VACCINATION_COUNT; orderNumber++) {
      const dose = currentVaccination[`date${orderNumber}`];
      if (!dose) break;
      const label = `Additional Dose #${newAdditionalDates.length + additionalDates.length + 1}`;
      newAdditionalDates.push({
        label,
        orderNumber,
      });
    }
    if (!newAdditionalDates.length) return;
    setAdditionalDates([...additionalDates, ...newAdditionalDates]);
  }, [currentVaccination, dates, additionalDates]);

  const handleAddDose = () => {
    setAdditionalDates([
      ...additionalDates,
      {
        label: `Additional Dose #${additionalDates.length + 1}`,
        orderNumber: dates[dates.length - 1].orderNumber + 1,
      },
    ]);
  };

  const handleRadioChange = ({ target: { value } }) => {
    handleInput({ value, id, propName: 'isPrimarySeriesComplete' });
  };

  const handleDateChange = (propName) => (value) => {
    handleInput({ value: value, id, propName });
  };

  const getDatesFormattedColumn = (datesColumn) => {
    return datesColumn.map(({ label, orderNumber }) => {
      const propName = `date${orderNumber}`;
      return (
        <CustomDatePicker key={label}
                          label={label}
                          value={currentVaccination?.[propName]}
                          handleInput={handleDateChange(propName)}
        />
      );
    }, [])
  };

  const getDatesTitleRow = () => (
    <Typography variant="overline" gutterBottom>
      Date(s):
    </Typography>
  );

  const addDoseButton = () => {
    return !!showAddDoseButton
      && dates.length < MAX_DOSES_PER_VACCINATION_COUNT
      && (
        <Button variant="outlined"
                onClick={handleAddDose}
                style={{ marginTop: '10px' }}
                disabled={!currentVaccination?.[`date${dates[dates.length - 1]?.orderNumber}`]}
        >
          <div style={{ lineHeight: 0 }}>
            Add dose
          </div>
          <AddIcon fontSize="small" sx={{ marginLeft: '10px' }}/>
        </Button>
      );
  };

  return (
    <Stack direction="column" justifyContent="flex-start" spacing={1}>
      {
        !!dates.length &&
        <>
          {!!datesColumns[1].length && getDatesTitleRow()}
          <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={2}>
            <Stack direction="column" justifyContent="flex-start" spacing={1}>
              {!datesColumns[1].length && getDatesTitleRow()}
              {getDatesFormattedColumn(datesColumns[0])}
              {!datesColumns[1].length && addDoseButton()}
            </Stack>
            {
              (!!datesColumns[1].length || !!showIsPrimarySeriesComplete) &&
              <Stack direction="column" justifyContent="space-between" spacing={2}>
                {
                  !!datesColumns[1].length &&
                  <Stack direction="column" justifyContent="flex-start" spacing={1}>
                    {getDatesFormattedColumn(datesColumns[1])}
                    {addDoseButton()}
                  </Stack>
                }
                {
                  !!showIsPrimarySeriesComplete &&
                  <Stack direction="column" justifyContent="flex-start">
                    <Typography variant="overline" gutterBottom>
                      Primary Series Complete?
                    </Typography>
                    <FormControl>
                      <RadioGroup row
                                  value={currentVaccination?.isPrimarySeriesComplete ? 1 : 0}
                                  onChange={handleRadioChange}
                      >
                        <FormControlLabel label="Yes"
                                          value={1}
                                          control={<Radio size="small"/>}
                        />
                        <FormControlLabel label="No"
                                          value={0}
                                          control={<Radio size="small"/>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                }
              </Stack>
            }
          </Stack>
        </>
      }
    </Stack>
  );
}