import { useStore } from '../store';
import { useStore as useVaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

// vaccinations and exemptions attachments chips - maybe rename or refactor in some way
export default function AttachmentsChips(props) {
  const { attachmentsKey: key, id, isCommon = false } = props;

  const [
    handleAttachmentChipClick,
  ] = useStore(
    (store) => [
      store.handleAttachmentChipClick,
    ],
    shallow
  );

  const [
    vaccinationsData,
    vaccinationsAttachments,
    newVaccinationsAttachments,
    deleteAttachment,
  ] = useVaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.vaccinationsAttachments,
      store.newVaccinationsAttachments,
      store.deleteAttachment,
    ],
    shallow
  );

  const filterFunc = (curr) => isCommon && !id ? !curr.id : curr.id === id;
  const attachments = isCommon ? newVaccinationsAttachments.filter(filterFunc) : vaccinationsData[id]?.[key];
  const moreAttachments = isCommon && vaccinationsAttachments.filter(filterFunc);
  const isExemptions = key === 'exemptionsAttachments';

  const handleFileDelete = (index) => {
    deleteAttachment({ id, index, key, isCommon });
  };

  const handleClick = ({ objectUrl, rid, name }) => () => {
    if (!rid && !objectUrl) return;
    handleAttachmentChipClick({ rid, objectUrl, fileName: name, type: isExemptions ? 'exem' : '' });
  };

  return (
    <Stack direction="row" alignItems="start" display="flex" flexWrap="wrap" gap={1}>
      {
        moreAttachments?.length
          ? moreAttachments.map(({ value: { name }, rid }) => {
            return (
              <Chip key={name}
                    label={name}
                    onClick={handleClick({ rid, name })}
                    size="small"
                    clickable
              />
            );
          })
          : null
      }
      {
        attachments?.length
          ? attachments.map(({ name, objectUrl, url, value, rid }, index) => {
            const label = name || value?.name;
            return (
              <Chip key={label}
                    label={label}
                    onClick={handleClick({ objectUrl, name: label, rid })}
                    onDelete={url ? null : () => handleFileDelete(index)}
                    size="small"
                    clickable
              />
            );
          })
          : null
      }
    </Stack>
  );
}
