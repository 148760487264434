import create from 'zustand';
import consts from './consts';
import supportedImageMimeTypes from './consts/commonly-supported-image-mime-types.json';
import mime from 'mime';
import { editPerson, getPersonData } from './api/persons';
import { getFile } from './api/attachments';
import { sendLogToServer } from './api/service';

const { LOADING_TIMEOUT } = consts;

const useStore = create((set, get) => ({
  personData: null,
  previouslySubmittedPersonData: {},
  onPersonDataChange: (v) => set(({ personData }) => ({ personData: { ...personData, ...v } })),
  fetchPersonData: async () => {
    const personData = await getPersonData();
    set({
      personData: {
        ...personData,
        signatureDate: personData.signatureDate || new Date(),
      },
      previouslySubmittedPersonData: personData,
    });
  },

  loadingStartTimeStamp: 0,
  checkLoadingTime: (willStartLoad) => set(({ loadingStartTimeStamp }) => {
    if (willStartLoad) return { loadingStartTimeStamp: Date.now() };
    const loadingTime = Date.now() - loadingStartTimeStamp;
    if (loadingTime > LOADING_TIMEOUT) {
      sendLogToServer({
        message: 'client loading timeout',
        details: { loadingTime },
      });
    }
    return { loadingStartTimeStamp: 0 };
  }),
  isLoading: false,
  setIsLoading: (v) => set(({ checkLoadingTime }) => {
    checkLoadingTime(v);
    return { isLoading: v };
  }),

  isAttachmentDialogOpened: false,
  attachmentDialogObject: {
    url: null,
    isImage: true,
    fileName: '',
    isLocal: false,
  },
  downloadAttachment: ({ fileName, objectUrl, isLocal }) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    if (!isLocal) {
      URL.revokeObjectURL(objectUrl);
    }
    set({ isLoading: false });
  },
  handleAttachmentChipClick: async ({ rid, objectUrl: objectUrlParam, fileName, type }) => {
    const mimeType = mime.getType(fileName?.split('.').pop());
    const isImage = mimeType?.split('/').shift() === 'image';
    const isApplicableForDisplaying = [...supportedImageMimeTypes, 'application/pdf'].includes(mimeType);
    set({ attachmentDialogObject: { isImage }, isLoading: true });
    let objectUrl = objectUrlParam;
    if (!objectUrl) {
      try {
        const blob = await getFile({ rid, type, fileName });
        objectUrl = URL.createObjectURL(blob);
      } catch (e) {
        console.error(e);
        set({ isLoading: false });
        return;
      }
    }
    const isLocal = objectUrlParam;
    if (!isApplicableForDisplaying) {
      get().downloadAttachment({ objectUrl, fileName, isLocal });
      return;
    }
    set({
      isLoading: false,
      isAttachmentDialogOpened: true,
      attachmentDialogObject: {
        url: objectUrl,
        isImage,
        fileName,
        isLocal,
      },
    });
  },
  closeAttachmentDialog: () => set(({ attachmentDialogObject }) => {
    if (!attachmentDialogObject.isLocal) {
      URL.revokeObjectURL(attachmentDialogObject.url);
    }
    return {
      isAttachmentDialogOpened: false,
      attachmentDialogObject: {
        ...attachmentDialogObject, // to reduce blinking
        url: null,
        fileName: '',
      },
    };
  }),

  handleSubmitAuthorization: async () => {
    const { personData = {} } = get();
    const {
      isReleaseAuthorized,
      isDataAttested,
      signedName,
      signatureDate,
    } = personData;
    if (!isReleaseAuthorized || !isDataAttested || !signedName || !signatureDate) {
      const error = new Error();
      error.userMessage = 'You must complete all the fields';
      throw error;
    }
    await editPerson({
      isReleaseAuthorized,
      isDataAttested,
      signedName,
      signatureDate,
    });
  },
}));

export { useStore };
