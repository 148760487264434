import { useHistory } from 'react-router-dom';
import { getPortalLanguageSection } from '../utils';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function MeningitisWaiverDialog() {
  const history = useHistory();

  const handleClose = () => {
    // TODO
    history.push({ pathname: window.location.pathname, search: window.location.search });
    window.history.replaceState({}, document.title, window.location.pathname + window.location.search);
  };

  return (
    <Dialog onClose={handleClose} open={true} fullWidth maxWidth="md">
      <DialogContent>
        <DialogContentText variant="inherit"
                           dangerouslySetInnerHTML={{
                             __html: getPortalLanguageSection('meningitis_waiver_additional_info')
                           }}
        >
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text">Close</Button>
      </DialogActions>
    </Dialog>
  );
}
