import { useStore as vaccinationsStore } from './vaccinations-store';
import { useStore } from '../store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import VaccinationCard from './vaccination-card';
import VaccinationsAttachments from './vaccinations-attachments';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const { VACCINATION_TYPE_RID_COVID } = consts;

export default function RequiredVaccinations() {
  const [
    personData,
  ] = useStore(
    (store) => [
      store.personData,
    ],
    shallow,
  );

  const [
    vaccinationTypes,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationTypes,
    ],
    shallow,
  );

  let covidCard = null;

  // TODO: filter this in the upper level
  const cards = vaccinationTypes.reduce((filtered, vaccinationType) => {
    if (vaccinationType.isOther) return filtered;
    const isRequired = (
      (
        personData.role === 'Student'
        || (personData.role === 'Employee' && personData.isActive && !personData.isRemote)
      ) && vaccinationType.requiredForStudents
    ) || (personData.role === 'Employee' && vaccinationType.requiredForEmployees);

    if (isRequired) {
      if (vaccinationType.id === VACCINATION_TYPE_RID_COVID) {
        covidCard = (
          <VaccinationCard key={vaccinationType.id} {...vaccinationType}/>
        );
      } else {
        filtered = [
          ...filtered,
          <VaccinationCard key={vaccinationType.id} {...vaccinationType}/>
        ];
      }
    }
    return filtered;
  }, []);

  if (covidCard) {
    cards.unshift(covidCard);
  }

  const isThereOnlyCovid = !!covidCard && cards.length === 1;

  // TODO: remove step when no vaccinations
  const noVaccinations = (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="button">
        No required vaccinations
      </Typography>
    </Box>
  );

  return (
    <>
      {
        !!cards.length
          ? (
            <>
              {cards}
              {
                !isThereOnlyCovid &&
                <VaccinationsAttachments/>
              }
            </>
          ) : noVaccinations
      }
    </>
  );
}
