import buildRequest from './index';

const request = buildRequest('service');

export async function sendLogToServer(data) {
  return request({
    type: 'post',
    route: 'log',
    data,
  });
}
