import { useEffect, useState } from 'react';
import { useStore } from '../store';
import { useStore as useTBStore } from './tuberculosis-store';
import shallow from 'zustand/shallow';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Add from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';

export default function TuberculosisSkinTestsSection() {
  const [
    handleAttachmentChipClick,
  ] = useStore(
    (store) => [
      store.handleAttachmentChipClick,
    ],
    shallow
  );

  const [
    setOpenedTbSkinTestIndex,
    tbHistoryData,
  ] = useTBStore(
    (store) => [
      store.setOpenedTbSkinTestIndex,
      store.tbHistoryData,
    ],
    shallow,
  );

  const skinTests = tbHistoryData?.tbTests?.skin || [];

  const [expanded, setExpanded] = useState(!!skinTests.length);

  useEffect(() => {
    setExpanded(!!skinTests.length);
  }, [skinTests.length]);

  const handleAttachmentClick = ({ rid, name, objectUrl }) => {
    if (!rid && !objectUrl) return;
    handleAttachmentChipClick({ rid, objectUrl, fileName: name, type: 'tb-tests' });
  };

  const handleAddClick = () => {
    setOpenedTbSkinTestIndex(skinTests.length);
    // we don't necessarily need to put length here, but any truthy value, because we're not editing
  };

  const handleEditClick = ({ index, isApproved }) => () => {
    if (isApproved) return;
    setOpenedTbSkinTestIndex(index);
  };

  return (
    <Paper variant="outlined">
      <Toolbar variant="dense" disableGutters sx={{ paddingX: '10px', paddingY: '5px' }}>
        <IconButton onClick={() => setExpanded(!expanded)} sx={{ display: skinTests.length ? '' : 'none' }}>
          {expanded ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
        <Typography variant="h6" sx={{ flex: '1 1 100%', paddingX: skinTests.length ? '' : '10px' }}>
          TB (Tuberculin) skin tests
        </Typography>
        <Tooltip title="Add skin test" placement="top-start">
          <IconButton color="primary" onClick={handleAddClick}>
            <Add/>
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Table size="small">
            <TableHead>
              <TableRow>
                {
                  [
                    '',
                    'Result:',
                    'Value:',
                    'Date Administered:',
                    'Date Read:',
                    'Attachment:',
                  ].map((curr, index) => {
                    return (
                      <TableCell key={index}>
                        <Typography variant="overline">
                          {curr}
                        </Typography>
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                skinTests.map((skinTest, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {
                        skinTest.isApproved
                          ?
                          <Typography variant="caption" color="green">
                            APPROVED
                          </Typography>
                          : <IconButton size="small"
                                        onClick={handleEditClick({
                                          index,
                                          isApproved: skinTest.isApproved,
                                        })}
                                        disabled={!!skinTest.isApproved}
                          >
                            <EditIcon fontSize="inherit"/>
                          </IconButton>
                      }
                    </TableCell>
                    <TableCell>
                      {skinTest.result}
                    </TableCell>
                    <TableCell>
                      {skinTest.value}
                    </TableCell>
                    <TableCell>
                      {skinTest.dateAdministered ? new Date(skinTest.dateAdministered).toLocaleDateString() : ''}
                    </TableCell>
                    <TableCell>
                      {skinTest.dateRead ? new Date(skinTest.dateRead).toLocaleDateString() : ''}
                    </TableCell>
                    <TableCell>
                      {
                        !!skinTest.attachment &&
                        <Chip clickable
                              size="small"
                              label={skinTest.attachment.filename || skinTest.attachment.name}
                              onClick={() => handleAttachmentClick({
                                rid: skinTest.rid,
                                name: skinTest.attachment.filename || skinTest.attachment.name,
                                objectUrl: skinTest.attachmentObjectUrl,
                              })}
                              sx={{ maxWidth: '150px' }}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Collapse>
      </TableContainer>
    </Paper>
  );
}