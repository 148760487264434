import buildRequest from './index';

const request = buildRequest('titers');

export async function get() {
  return request({});
}

export async function getTiterTypes() {
  return request({ route: 'types' });
}

export async function save(data) {
  return request({
    type: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}