import React from 'react';
import icons from '../svgstore.svg';

export default function SvgIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // className={`icon icon-${props.name}`}
      style={{ height: 'inherit' }}
    >
      <use xlinkHref={`${icons}#${props.name}`}/>
    </svg>
  )
};
