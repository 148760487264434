import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { sendLogToServer } from './api/service';

window.onerror = (errorMsg, url, lineNumber, columnNumber, error) => {
  sendLogToServer({
    message: 'client onerror',
    error: errorMsg,
    details: { url, lineNumber, columnNumber, stack: error?.stack }
  });
};
window.addEventListener('unhandledrejection', (event = { reason: {} }) => {
  const error = event.reason || {};
  const details = {
    stack: error.stack || 'no stack provided',
  };
  if (error.isAxiosError) {
    const url = error.config?.url;
    if (url === 'api/service/log') return;
    details.url = url;
  }
  sendLogToServer({
    message: 'client unhandledrejection',
    error: error.message,
    details,
  });
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <App/>
    </Router>
);
