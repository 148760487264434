import buildRequest from './index';

const request = buildRequest('persons');

export async function getPersonData() {
  return request({});
}

export async function editPerson(data) {
  return request({
    type: 'patch',
    data,
  });
}

export async function fillProgress(data) {
  return request({
    route: 'progress',
    type: 'patch',
    data,
  });
}