import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export default function CustomTooltip(props) {
  return (
    <Tooltip enterTouchDelay={0}
             leaveTouchDelay={10000 * 60}
             title={
               <Typography variant="body2">
                 {props.title}
               </Typography>
             }
    >
      <IconButton>
        {props.children}
      </IconButton>
    </Tooltip>
  );
};