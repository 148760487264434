import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import links from '../consts/links.json';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import LogoSvg from './svg-icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

const pages = [{
  text: 'Home',
  link: '/',
}, {
  text: 'Vaccinations',
  link: '/vaccinations',
}, {
  text: 'Profile',
  link: '/profile',
  condition: ({ authState: { isAuthenticated } }) => isAuthenticated,
}, {
  text: 'Sign Out',
  action: async ({ logout }) => logout(),
  condition: ({ authState: { isAuthenticated } }) => isAuthenticated
}, {
  text: 'Sign In',
  action: async ({ login }) => login(),
  condition: ({ authState: { isAuthenticated, isPending } }) => !isPending && !isAuthenticated
}];

const styles = {
  linkButton: {
    my: 2, color: 'white', display: 'block', fontSize: '12.6px',
  }
};

export default function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const oktaState = useOktaAuth();

  if (!oktaState.authState) {
    return null;
  }

  oktaState.login = async () => oktaState.oktaAuth.signInWithRedirect();
  oktaState.logout = async () => oktaState.oktaAuth.signOut();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" enableColorOnDark sx={{ boxShadow: 'none', height: '64px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ fill: '#fff', height: '54px', my: 0.5 }}
               component="a"
               title="Saint Louis University SLU Logo Left"
               href={links.SLU}
          >
            <LogoSvg name="logo-left"/>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end' }}>
            <IconButton onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon/>
            </IconButton>
            <Menu anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
            >
              {
                pages.reduce((filtered, { text, link, action, condition }) => {
                  if (typeof condition === 'undefined' || !!condition(oktaState)) {
                    const item = (
                      <MenuItem key={text}
                                onClick={(e) => {
                                  handleCloseNavMenu(e);
                                  action && action(oktaState)
                                }}
                      >
                        <Typography component={action ? 'div' : Link} to={link} color="inherit">
                          {text}
                        </Typography>
                      </MenuItem>
                    );
                    filtered.push(item);
                  }
                  return filtered;
                }, [])
              }
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
            {
              pages.reduce((filtered, { text, link, action, condition }) => {
                if (typeof condition === 'undefined' || !!condition(oktaState)) {
                  const item = (
                    <Button key={text}
                            component={action ? 'div' : Link}
                            to={link}
                            onClick={() => action && action(oktaState)}
                            sx={styles.linkButton}
                    >
                      {text}
                    </Button>
                  );
                  filtered.push(item);
                }
                return filtered;
              }, [])
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
