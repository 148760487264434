import { useStore } from '../store';
import { getPortalLanguageSection } from '../utils';

export default function Instructions() {
  const personData = useStore((s) => s.personData);

  return (
    <div dangerouslySetInnerHTML={{
      __html: getPortalLanguageSection(
        personData?.role === 'Employee'
          ? 'instruction_employee'
          : 'instruction_student'
      )
    }}
    />
  );
}
