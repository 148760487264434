import buildRequest from './index';

const request = buildRequest('diseases-history');

export async function get() {
  return request({});
}

export async function save(data) {
  return request({
    type: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}