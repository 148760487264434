import create from 'zustand';
import { useStore as useCommonStore } from '../store';
import * as api from '../api/tb-history';
import { editPerson } from '../api/persons';

const { onPersonDataChange } = useCommonStore.getState();

const useStore = create((set, get) => ({
  tbHistoryData: undefined,

  openedTbBloodTestDialogIndex: null,
  setOpenedTbBloodTestDialogIndex: (v) => set({ openedTbBloodTestDialogIndex: v }),

  openedTbSkinTestIndex: null,
  setOpenedTbSkinTestIndex: (v) => set({ openedTbSkinTestIndex: v }),

  fetchTBData: async () => {
    const tbHistoryData = await api.get();
    set({ tbHistoryData });
  },
  onTbHistoryChange: (valuesToMerge) => set((state) => {
    return {
      tbHistoryData: {
        ...state.tbHistoryData,
        ...valuesToMerge,
      }
    };
  }),
  onTbTreatmentChange: (valuesToMerge) => set((state) => {
    return {
      tbHistoryData: {
        ...state.tbHistoryData,
        treatment: {
          ...(state.tbHistoryData?.treatment),
          ...valuesToMerge,
        }
      }
    };
  }),

  handleSubmitTbHistory: async () => {
    const { tbHistoryData, fetchTBData } = get();
    const { treatment = {}, tbTests = {}, } = tbHistoryData || {};
    if (!Object.keys(treatment).length && !tbTests.blood.length && !tbTests.skin.length) return;
    await api.save(tbHistoryData); // TODO: map fields to delete redundant ones?
    if (tbHistoryData.attachmentObjectUrl) {
      URL.revokeObjectURL(tbHistoryData.attachmentObjectUrl);
    }
    [
      ...tbHistoryData.tbTests.blood,
      ...tbHistoryData.tbTests.skin,
    ].forEach(({ attachmentObjectUrl }) => {
      if (attachmentObjectUrl) {
        URL.revokeObjectURL(attachmentObjectUrl);
      }
    });
    fetchTBData();
  },

  handleSubmitTbQuestionnaire: async () => {
    const { tbHistoryData, fetchTBData } = get();
    const { questionnaire } = tbHistoryData;

    // Check for null or undefined in answers
    const isValid = !questionnaire.some(x => isNaN(x + ''));
    if (!isValid) {
      const error = new Error();
      error.userMessage = 'You must answer all questions to proceed';
      throw error;
    }

    const isPositive = questionnaire.some(x => !!x);
    const newNoNeedTbTestValue = !isPositive;

    await api.saveQuestionnaire({
      rid: tbHistoryData.rid,
      questionnaire: questionnaire,
    });
    await editPerson({ noNeedTbTest: newNoNeedTbTestValue });
    onPersonDataChange({ noNeedTbTest: newNoNeedTbTestValue });
    fetchTBData();
  },
}));

export { useStore };
