import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

export default function CustomDatePicker(props) {
  const {
    label = null,
    value = null,
    handleInput,
    disabled = false,
    disableFuture = false,
    additionalProps = {},
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker label={label}
                  disabled={disabled}
                  disableFuture={disableFuture}
                  value={value}
                  onChange={handleInput}
                  renderInput={(params) => (
                    <TextField variant="standard"
                               size="small"
                               margin="dense"
                               autoComplete="off"
                               {...params}
                    />
                  )}
                  {...additionalProps}
      />
    </LocalizationProvider>
  );
}
