import { useState } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import consts from '../consts';
import AttachmentsChips from './attachments-chips';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const { MAX_CARD_ATTACHMENTS_COUNT } = consts;

export default function VaccinationCardAttachment(props) {
  const { id } = props;

  const [attachments, setAttachments] = useState('');

  const [
    vaccinationsData,
    handleAttachmentsInput,
  ] = vaccinationsStore(
    (store) => [
      store.vaccinationsData,
      store.handleAttachmentsInput,
    ],
    shallow,
  );

  const isAttachmentButtonDisabled = vaccinationsData[id]?.attachments?.length >= MAX_CARD_ATTACHMENTS_COUNT;

  const handleFilesUpload = ({ target: { files } }) => {
    handleAttachmentsInput({ files, id });
    setAttachments(''); // TODO
  };

  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <Stack direction="column" justifyContent="space-between" spacing={1}>
        <label htmlFor={`attachment-${id}`}>
          <Button variant="contained" size="small" component="span" disabled={isAttachmentButtonDisabled}>
            Attach files*
          </Button>
          <input hidden
                 multiple
                 disabled={isAttachmentButtonDisabled}
                 id={`attachment-${id}`}
                 type="file"
                 // value={vaccinationsData[id]?.attachments || ''}
                 value={attachments}
                 onChange={handleFilesUpload}
          />
        </label>
        <AttachmentsChips attachmentsKey="attachments" isCommon={true} id={id}/>
      </Stack>
    </Stack>
  );
}
