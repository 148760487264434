import { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  HashRouter,
} from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import theme from './miu-theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import NavBar from './components/nav-bar';
import Footer from './components/footer';
import Notification from './components/notification';
import Home from './pages/home';
import Profile from './pages/profile';
import Vaccinations from './pages/vaccinations';
import oktaAuth from './okta-auth';
import moment from 'moment-timezone';
import MeningitisWaiverDialog from './components/meningitis-waiver-dialog';
import { fillProgress } from './api/persons';

moment.tz.setDefault('Etc/UTC');

function AppContent() {
  const oktaState = useOktaAuth();
  const [isProgressFilled, setIsProgressFilled] = useState(false);

  useEffect(() => {
    if (!isProgressFilled && oktaState?.authState?.isAuthenticated) {
      setIsProgressFilled(true);
      fillProgress({ propName: 'accessedPortal' });
    }
  }, [oktaState?.authState, isProgressFilled]);

  return (
    <ThemeProvider theme={theme}>
      <NavBar/>
      <Notification/>
      <Switch>
        <SecureRoute path="/vaccinations" component={Vaccinations}/>
        <SecureRoute path="/profile" component={Profile}/>
        <Route path="/login/callback*" component={LoginCallback}/>
        <Route path="*" component={Home}/>
      </Switch>
      <HashRouter hashType="noslash">
        <Route path="/meningitis-info-dialog" component={MeningitisWaiverDialog}/>
      </HashRouter>
      <Footer/>
    </ThemeProvider>
  );
}

export default function App() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
    >
      <AppContent/>
    </Security>
  );
}
