import { getPortalLanguageSection } from '../utils';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function Home() {
  return (
    <Container maxWidth="md" sx={{ marginBottom: '100px' }}>
      <div dangerouslySetInnerHTML={{ __html: getPortalLanguageSection('home_page') }}/>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Link to="/vaccinations">
          <Button>
            Submit vaccination data
          </Button>
        </Link>
      </Box>
    </Container>
  );
}
