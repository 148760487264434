import create from 'zustand';

const useStore = create((set, get) => ({
  notificationMessage: '',
  notificationSeverity: 'success',
  isNotificationShown: false,
  showNotification: ({ type = 'success', message = '' }) => {
    set({ notificationMessage: message, notificationSeverity: type, isNotificationShown: true });
  },
  hideNotification: (event, reason) => {
    if (reason === 'clickaway') return;
    set({ isNotificationShown: false });
  },
}));

export { useStore };
