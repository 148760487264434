import buildRequest from './index';

const request = buildRequest('vaccinations');

export async function save(data) {
  return request({
    type: 'put',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function getVaccinationsData() {
  return request({});
}

export async function getVaccinationsAttachments() {
  return request({ route: 'attachments' });
}

export async function saveVaccinationsAttachments(data) {
  return request({
    route: 'attachments',
    type: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function getVaccinationsTypes() {
  return request({ route: 'types' });
}

export async function getExemptionsData() {
  return request({ route: 'exemptions' });
}
