import { useMemo, useState } from 'react';
import { useStore as vaccinationsStore } from './vaccinations-store';
import shallow from 'zustand/shallow';
import { getPortalLanguageSection } from '../utils';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import VaccinationCard from './vaccination-card';

export default function OtherVaccinations() {
  const [
    titers,
    vaccinationTypes,
    vaccinationsData,
    addVaccination,
  ] = vaccinationsStore(
    (store) => [
      store.titers,
      store.vaccinationTypes,
      store.vaccinationsData,
      store.addVaccination,
    ],
    shallow,
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const otherVaccinationTypes = useMemo(
    () => {
      const reducedTypes = vaccinationTypes.reduce((filtered, vaccinationType) => {
        if (!vaccinationType?.isOther) return filtered;
        const currentTypeTiters = titers.filter((titer) => {
          return titer.vaccinationType === vaccinationType.id;
        });
        const isSomeTiters = !!currentTypeTiters.length;
        const vaccination = vaccinationsData[vaccinationType.id];
        const vaccinationTypeToAdd = {
          ...vaccinationType,
          isVaccination: !!vaccination,
          isSomeTiters,
        };
        if (vaccination || isSomeTiters) {
          vaccinationTypeToAdd.dateCreated = vaccination?.dateCreated
            || Math.min(...currentTypeTiters.map((titer) => titer.dateCreated));
        }
        return [
          ...filtered,
          vaccinationTypeToAdd,
        ];
      }, []);
      reducedTypes.sort((a, b) => (b.dateCreated || 0) - (a.dateCreated || 0));
      return reducedTypes;
    },
    [titers, vaccinationTypes, vaccinationsData]
  );

  const presentVaccinationsVaccinationTypes = useMemo(
    () => otherVaccinationTypes.filter((type) => type.isVaccination || type.isSomeTiters),
    [otherVaccinationTypes]
  );

  const reversedOtherVaccinationTypes = useMemo(
    () => [...otherVaccinationTypes].reverse(),
    [otherVaccinationTypes]
  );

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (vaccinationType) => (e) => {
    handleMenuClose(e);
    addVaccination(vaccinationType.id);
  };

  return (
    <Stack direction="column" justifyContent="space-between" spacing={2} marginY={4}>
      <Divider/>
      <Typography variant="body1"
                  dangerouslySetInnerHTML={{ __html: getPortalLanguageSection('other_vaccinations_heading') }}
      />
      <div style={{ textAlign: 'center', margin: '0' }}>
        <Button variant="outlined" onClick={handleMenuOpen}>
          <div style={{ lineHeight: 0 }}>
            Add vaccination
          </div>
          <AddIcon fontSize="small" sx={{ marginLeft: '10px' }}/>
        </Button>
      </div>
      <Menu anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
      >
        {
          reversedOtherVaccinationTypes
            .map((vaccinationType) => {
              return (
                <MenuItem key={vaccinationType.id}
                          onClick={handleMenuClick(vaccinationType)}
                          disabled={vaccinationType.isVaccination || vaccinationType.isSomeTiters}
                >
                  <Typography variant="overline">
                    {vaccinationType.name}
                  </Typography>
                </MenuItem>
              );
            })
        }
      </Menu>
      {
        presentVaccinationsVaccinationTypes.map((vaccinationType) => {
          return (
            <VaccinationCard key={vaccinationType.id}
                             {...vaccinationType}
                             showExemptionCheckbox={false}
                             showRemove={
                               vaccinationType.isOther
                               && !vaccinationsData?.[vaccinationType.id]?.rid
                               && !vaccinationType.isSomeTiters
                             }
            />
          );
        })
      }
      <Divider/>
    </Stack>
  );
}
