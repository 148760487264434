import create from 'zustand';
import { getWaiver, saveWaiver } from '../api/meningitis-waivers';

const useStore = create((set, get) => ({
  meningitisWaiver: {},
  previouslySubmittedMeningitisWaiver: {},

  fetchMeningitisWaiver: async () => {
    const meningitisWaiver = await getWaiver();
    set({
      meningitisWaiver: {
        ...meningitisWaiver,
        waiverSignedDate: meningitisWaiver.waiverSignedDate || new Date(),
      },
      previouslySubmittedMeningitisWaiver: meningitisWaiver,
    });
  },

  onMeningitisWaiverChange: (valuesToMerge) => set((state) => {
    return {
      meningitisWaiver: {
        ...state.meningitisWaiver,
        ...valuesToMerge,
      }
    };
  }),

  handleSubmitMeningitisWaiver: async () => {
    const { meningitisWaiver, fetchMeningitisWaiver } = get();
    if (!meningitisWaiver || !Object.keys(meningitisWaiver).length) return;
    if (!meningitisWaiver.waiverSignedDate) {
      const error = new Error();
      error.userMessage = 'You must complete Waiver Signed Date';
      throw error;
    }
    await saveWaiver(meningitisWaiver);
    if (meningitisWaiver.attachmentObjectUrl) {
      URL.revokeObjectURL(meningitisWaiver.attachmentObjectUrl);
    }
    fetchMeningitisWaiver();
  },
}));

export { useStore };
