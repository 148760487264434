import { OktaAuth } from '@okta/okta-auth-js';

const configDev = {
  issuer: 'https://auth-test.slu.edu/oauth2/default',
  clientId: '0oa4d8ajhwoPEdGkx0x7',
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  postLogoutRedirectUri: 'http://localhost:3000/',
};

const configProd = {
  issuer: 'https://auth.slu.edu/oauth2/default',
  clientId: '0oadvy7dey4sXruYn297',
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  postLogoutRedirectUri: 'https://vaccination.slu.edu/',
}

const config = process.env.NODE_ENV === 'production'
  ? configProd : configDev;

const oktaAuth = new OktaAuth(config);

export default oktaAuth;